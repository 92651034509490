
  
  // Replace with your actual Firebase configuration details
  export const firebaseConfig = {
    // ... your Firebase configuration details here ...
    apiKey: "AIzaSyAJgzONsJyahTjR3rIaOI778UWLgjSMrvo",
    authDomain: "shopmore4u.firebaseapp.com",
    projectId: "shopmore4u",
    storageBucket: "shopmore4u.appspot.com",
    messagingSenderId: "623024676037",
    appId: "1:623024676037:web:021822d3aa7fe29ea4e4ac",
    measurementId: "G-5N7VCJL65G"
  };

  